<template>
  <div class="confirm-checkout">
    <!--(Початок). Контейнер із інформацією-->
    <div class="grey lighten-4">
      <v-container fluid class="container-max-width py-0 grey--text text--darken-2">
        <div class="grey lighten-4 py-4">
          <v-row no-gutters class="flex-nowrap" align="start">
            <span>
              <v-icon
                size="56px"
                color="primary"
                class="pr-4">
                mdi-cart-outline
              </v-icon>
            </span>
            <div>
              <h3 class="text-uppercase">{{ check ? 'Перевірка статусу оплати' :  'Підтвердження оплати' }}</h3>
              <p class="mb-1">{{ check ? 'Перевірка статусу транзакції по id' : 'Тут Ви зможете перевірити статус транзакції' }} </p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>
    <div id="hidden-url">
    </div>
    <!--(Кінець). Контейнер із інформацією-->

    <v-container fluid class="container-max-width pt-0" v-if="item">
      <v-card class="mb-2" min-height="140px" v-if="item.state !== null && item.state !== undefined">
        <v-card-text class="pt-3 pb-2">
          <h3>Статус оплати</h3>
          <p class="mb-0 mt-1 py-0" @click="copyUrl()">Для перевірки статусу оплати необхідно зберегти поточне посилання, виконавши команду
            <a href="#" @click.stop="copyUrl()" style="text-decoration: none">"Копіювати посилання"</a> або додайте поточну сторінку в закладки (CTRL+D)</p>
        </v-card-text>
        <v-divider/>
        <v-card-text>
          <v-row align="center" class="text-uppercase">
            <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="12" class="checkout-title text--lighten-2 row-padding">Всього: </v-col>
            <v-col :cols="cols(0, 8, 8, 8)" md="8" sm="8" xs="12" class="checkout-subtitle row-padding">{{ item.sum | hrn}}</v-col>
          </v-row>
          <v-row align="center" class="text-uppercase">
            <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="12" class="checkout-title text--lighten-2 row-padding">Дата: </v-col>
            <v-col :cols="cols(0, 8, 8, 8)" md="8" sm="8" xs="12" class="checkout-subtitle row-padding"> {{ getDateFromLog(item) }} </v-col>
          </v-row>
          <v-row align="center" class="text-uppercase">
            <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="12" class="checkout-title text--lighten-2 row-padding">Картка: </v-col>
            <v-col :cols="cols(0, 8, 8, 8)" md="8" sm="8" xs="12" class="checkout-subtitle row-padding"> {{ pan }} </v-col>
          </v-row>
          <v-row align="center" class="text-uppercase">
            <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="12" class="checkout-title text--lighten-2 row-padding">ID: </v-col>
            <v-col :cols="cols(0, 8, 8, 8)" md="8" sm="8" xs="12" class="checkout-subtitle row-padding"> {{ payments[0] }} </v-col>
          </v-row>
          <v-row align="center" class="text-uppercase">
            <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="12" class="checkout-title text--lighten-2 row-padding">Статус: </v-col>
            <v-col :cols="cols(0, 8, 8, 8)" md="8" sm="8" xs="12" class="checkout-subtitle row-padding">
              <v-chip
                small
                outlined
                class="font-weight-regular"
                :color="getChipClass(item)"
              >
                {{ getStateTranslate(item.state) }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row align="center" class="text-uppercase">
            <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="12" class="checkout-title row-padding"/>
            <v-col :cols="cols(0, 8, 8, 8)" md="8" sm="8" xs="12" class="checkout-subtitle row-padding">
              <v-btn width="209px" color="grey lighten-2" class="grey--text text--darken-2" small depressed @click.stop="checkState">Перевірити статус оплати</v-btn>
              <v-btn width="209px" color="grey lighten-2" class="grey--text text--darken-2 mt-2" small depressed @click.stop="copyUrl()">
                <v-icon
                  small
                  left
                >
                  mdi-clipboard-file-outline
                </v-icon>
                Копіювати посилання
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-text class="pt-0">
          <v-row align="center">
            <v-col cols="12" md="12" sm="12" xs="12" class="checkout-title pb-0 pr-7 text-right">Деталізація платежу</v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="pt-1">
              <v-list dense class="pt-0">
                <v-list-item>
                  <v-list-item-avatar height="30px">
                    <v-icon>
                      mdi-cart
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-1">
                    <v-container fluid class="pa-0">
                      <v-row align="center" class="grey--text text--darken-2">
                        <v-col cols="12" class="py-0 pb-1">
                          <p class="mb-0">{{ item.address.title }}</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(element, index) in item.services" :key="index" class="service-description">
                  <v-list-item-avatar min-height="0" height="0">
                    <v-icon>
<!--                      mdi-cart-->
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-1">
                    <v-container fluid class="pa-0">
                      <v-row align="center" class="grey--text text--darken-2">
                        <v-col :cols="cols(0, 8, 8, 8)" md="8" class="font-weight-thin py-0">
                          <div>{{ element.service.organization.name }}</div>
                          <div>{{ element.service.name_printable }}{{ getPersonId(element) }}</div>
                        </v-col>
                        <v-col :cols="cols(0, 4, 4, 4)" md="4" class="py-0 text-right">
                          {{ element.sum | hrn }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.state === 'created'">
                  <v-list-item-avatar height="0">
                    <v-icon>
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-container fluid class="pa-0">
                      <v-row align="center" class="grey--text text--darken-2">
                        <v-col cols="12" class="py-0 pb-1">
                          <v-btn color="primary lighten-1" small depressed block :disabled="!card_id || loading || check" @click.stop="payment">Оплатити</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>



  </div>
</template>


<script>
    import { getImage, getImageClass, getChipClass, getStateTranslate, getOperationType, getOperation, formatDate, cols, checkout, confirm } from '@/utils'
    // import { CardAPI } from '@/api/tasCard'
    import { PaymentTasAPI } from '@/api/tasPayments'
    import {assign, cloneDeep, each, map, uniqBy} from "lodash";


  export default {
      name: 'confirm-checkout',
      data: () => {
          return {
              item: {},
              payments: [],
              originData: [],
              card_id: undefined,
              pan: undefined,
              loading: false,
              group: false,
              check: false
          }
      },
    methods: {
      getServices(item) {
        let services = [];
        let serviceArray = this.originData.filter(dataItem => dataItem.group_id === item.group_id);
        each(serviceArray, itemArray => {
          let newService = {};
          newService.service = itemArray.service;
          newService.sum = itemArray.sum;
          newService.person_id_internal = itemArray.person_id_internal;
          newService.person_id_internal_text = itemArray.person_id_internal_text;
          services.push(newService)
        });
        return services
      },
      async paymentView() {
        try {
          let {data} = await PaymentTasAPI.view({ group: this.payments[0] });
          this.originData = cloneDeep(data);

          let basketList =  map(data, (item) => {
            return assign(item, {
              person_id_internal: null,
              service: {},
              sum: 0,
              selected: true,
              state: ((item.payment === null) || (item.payment === undefined)) ? 'created' : item.payment.state,
            })
          });

          basketList = map(uniqBy(basketList, 'group_id'), (item) => {
            return assign(item, {
              services: this.getServices(item),
              operation_type: this.getOperationType(item),
              operation: this.getOperation(item),
              selected: true,
            })
          });

          basketList = each(basketList, item => {
            item.sum = item.services.reduce((total, s) => {
              return total + s.sum;
            }, 0);
            item.selected = item.state === 'created';
          });

          this.item = basketList[0];
          delete this.item['service'];
          this.originData = [];
          if (this.item.payment) {
            this.check = true;
            let card = this.item.payment.card;
            this.pan = card.pan;
            this.card_id = card.id;
          } else {
            this.check = false;
          }

        } catch (e) {
          this.$snackbar("Помилка одержання інформації", 'error')
        }
      },
      getDateFromLog(item) {
        if (item.payment === null) {
          return this.formatDate(item.create_date);
        }
        return this.formatDate(item.payment.log[item.state].date)
      },
      // async checkout(card_id, payments) {
      //   let success = null;
      //   let payload = {};
      //   try {
      //     const {data} = await PaymentTasAPI.checkout({ 'card': card_id, 'payments': payments });
      //     const [sum, id] = data;
      //
      //     if (sum && id) {
      //       success = true;
      //       payload['sum'] = sum;
      //       payload['id'] = id;
      //     }
      //   } catch (e) {
      //     this.$snackbar("Платіж не підготовлено", 'error')
      //   }
      //
      //   return await new Promise(function (resolve, reject) {
      //     if (success) {
      //       resolve( payload );
      //     } else {
      //       reject({});
      //     }
      //   });
      // },
      // async confirm(id) {
      //   let success = null;
      //   let payload = {};
      //   try {
      //     const {data} = await PaymentTasAPI.confirm({ 'payment_id': id });
      //     if (data) {
      //       success = true;
      //       payload['payment_id'] = data.payment_id;
      //       payload['url'] = data.url;
      //     }
      //   } catch (e) {
      //     this.$snackbar("Платіж не підтверджено", 'error')
      //   }
      //
      //   return await new Promise(function (resolve, reject) {
      //     if (success) {
      //       resolve(payload);
      //     } else {
      //       reject(payload);
      //     }
      //   });
      // },
      payment() {
        this.loading = true;
        this.checkout(this.card_id, this.payments)
          .then((payload) => {
            if (payload.id) {
              this.confirm(payload.id)
                .then((confirmPayload) => {
                  if (confirmPayload.url) {
                    window.location.href = confirmPayload.url;
                  } else {
                    this.$router.push({ name: 'confirmCheckout', query: { payments: this.payments }});
                  }
                }).catch(() => {
                this.$snackbar("Помилка підтвердження платежу", 'error')
              })
            }
          });

        this.loading = false;
      },
      async checkState() {
        try {
          let uniqId = [];
          // await each(this.basketList, item => {
          //   if(item.payment) {
          //     if (!uniqId.includes(item.payment.id)) {
          //       uniqId.push(item.payment.id)
          //     }
          //   }
          // });
          if (this.item.payment) {
            uniqId.push(this.item.payment.id)
          }

          if (uniqId.length) {
            let {data} = await PaymentTasAPI.state( { 'acquiring': 'tas', 'payments' : uniqId } );

            if (data.length) {
              this.item.state = data[0];
            }
          } else {
            if (this.payments.length) {
              let {data} = await PaymentTasAPI.state( { 'acquiring': 'tas', 'payments' : this.payments } );

              if (data.length) {
                this.item.state = data[0];
              }
            }
          }


        } catch (e) {
          this.$snackbar("Помилка перевірки статусу платежу", 'error')
        }
      },
      async reloadData() {
        let payments = this.$route.query['payments'];
        if (typeof(payments) === 'string') {
          this.payments = [payments];
        }
        if (payments instanceof Array) {
          this.payments = payments;
        }
        this.card_id = this.$route.query['card'] || undefined;
        this.pan = this.$route.query['pan'] || undefined;
        // this.group = this.$route.query['group'] || false;

        await this.paymentView();

        // if (this.group) {
        //   console.log('by payment_id');
        // } else {
        //   await this.paymentView();
        // }

      },
      copyUrl() {
        let dummy = document.createElement('input'), text = window.location.href;
        document.getElementById('hidden-url').appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.getElementById('hidden-url').removeChild(dummy);
        this.$snackbar("Посилання скопійовано. Вставте його в вдресний рядок броузера", 'success')
      },
      getPersonId(item) {
        const person_id_int = item.person_id_internal_text
                                ? item.person_id_internal_text : item.person_id_internal || ''

        return person_id_int ? ` (о/р ${person_id_int})` : '';
      },
      cols,
      getOperationType,
      getOperation,
      formatDate,
      getImage,
      getImageClass,
      getChipClass,
      getStateTranslate,
      checkout,
      confirm
    },
    async mounted() {
      await this.reloadData();
      await this.checkState();
    }
  }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 400px;
  }
  .checkout-title {
    font-size: 1.02em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .checkout-subtitle {
    font-size: 1.09em;
    font-weight: 300;
  }
  .row-padding {
    padding-top: 7px;
    padding-bottom: 7px;
  }
</style>
